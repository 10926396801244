import { Stack, Tabs } from '@mantine/core'
import { IconChartColumn } from '@tabler/icons-react'
import { useState } from 'react'

import ContentModuleInsights from '@/components/ContentModuleInsights'
import InsightsCharts from '@/components/InsightsCharts'
import { Screen } from '@/components/Screen'
import SmeMetrics from '@/components/SmeMetrics'
import TopicInsights from '@/components/TopicInsights'
import { useGlobalState } from '@/hooks/useGlobalState'

const TabKeys = {
  summary: 'summary',
  topics: 'topics',
  contentModules: 'contentModules',
  smeMetrics: 'smeMetrics',
} as const

export function MetricsScreen() {
  const { authenticationStore } = useGlobalState()
  const { user } = authenticationStore
  const [activeTab, setActiveTab] = useState<string | null>(TabKeys.summary)

  const isSponsorAdmin = (): boolean => {
    // TODO: Verify this is the correct way to check if the user is a sponsor admin
    return (user?.isSponsorAdmin || user?.isSponsor) ?? false
  }

  return (
    <Screen title="Metrics" icon={IconChartColumn}>
      <Stack gap="md">
        <Tabs value={activeTab} onChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Tab value={TabKeys.summary}>Overview</Tabs.Tab>
            <Tabs.Tab value={TabKeys.topics}>Topics</Tabs.Tab>
            <Tabs.Tab value={TabKeys.contentModules}>Content Modules</Tabs.Tab>
            {isSponsorAdmin() && <Tabs.Tab value={TabKeys.smeMetrics}>SME Metrics</Tabs.Tab>}
          </Tabs.List>
          <Tabs.Panel value={TabKeys.summary} pt="md">
            {activeTab === TabKeys.summary && <InsightsCharts />}
          </Tabs.Panel>
          <Tabs.Panel value={TabKeys.topics} pt="md">
            {activeTab === TabKeys.topics && <TopicInsights />}
          </Tabs.Panel>
          <Tabs.Panel value={TabKeys.contentModules} pt="md">
            {activeTab === TabKeys.contentModules && <ContentModuleInsights />}
          </Tabs.Panel>
          {isSponsorAdmin() && (
            <Tabs.Panel value={TabKeys.smeMetrics} pt="md">
              {activeTab === TabKeys.smeMetrics && <SmeMetrics />}
            </Tabs.Panel>
          )}
        </Tabs>
      </Stack>
    </Screen>
  )
}
