import ChartSkeleton from './ChartSkeleton'
import DataErrorScreen from './DataErrorScreen'

export type TabChartWrapperProps = {
  isActive: boolean
  isLoading: boolean
  error: any
  children: React.ReactNode
}
export default function TabChartWrapper({ isActive, isLoading, error, children }: TabChartWrapperProps) {
  if (!isActive) return null

  if (isLoading) return <ChartSkeleton />

  if (error !== null) return <DataErrorScreen />

  return <>{children}</>
}
