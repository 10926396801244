import type { CompositeChartSeries, LineChartSeries } from '@mantine/charts'

import { colors } from '@/configs/colors'
import type { DateRangeRequest } from '@/services/api/api.types'

/**
 * Replace any '.', the graph library can't handle them in the labels
 */
export const sanitizeLabel = (label: string): string => {
  return label.replace(/\./g, '_')
}

/**
 * Format the date for x-axis labels in a chart
 */
export const formatChartDate = (date: string | null, showYear: boolean = false) => {
  if (!date) return ''
  return new Date(date).toLocaleDateString('en-US', {
    year: showYear ? 'numeric' : undefined,
    month: 'short',
    day: '2-digit',
  })
}

export const getRelativeDateRange = (daysAgo: number): DateRangeRequest => {
  return {
    date_from: `-${daysAgo}d`,
    date_to: null,
    explicit_date: false,
  }
}

export const createLineChartSeries = <T extends { label: string }>(items: T[]): LineChartSeries[] => {
  return items.map((item, index) => ({
    name: sanitizeLabel(item.label),
    color: colors.chartColors[index % colors.chartColors.length],
    label: item.label,
  }))
}

/**
 * Series for the conversations composite charts used in ContentModuleInsights and TopicInsights
 */
export const conversationsSeries: CompositeChartSeries[] = [
  {
    name: 'total',
    label: 'Total Conversations',
    color: colors.chartColors[0],
    type: 'line',
  },
  {
    name: 'unique',
    label: 'Unique User Conversations',
    color: colors.chartColors[1],
    type: 'line',
  },
  {
    name: 'allConversations',
    label: 'All Conversations',
    color: 'rgba(50, 204, 102, 0.25)',
    type: 'bar',
  },
]
