import { Group, Stack, Text } from '@mantine/core'
import { IconExclamationCircle } from '@tabler/icons-react'

export default function DataErrorScreen() {
  return (
    <Stack justify="center" align="center" h="100%" bg="gray.0">
      <Group gap="xs">
        <IconExclamationCircle color="gray" />
        <Text c="dimmed">An error occurred while loading the data</Text>
      </Group>
    </Stack>
  )
}
