import type { ModalProps, ScrollAreaProps } from '@mantine/core'
import { Modal, ScrollArea, Stack, Text } from '@mantine/core'
import type { UseFormReturnType } from '@mantine/form'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'
import { IconHexagons } from '@tabler/icons-react'
import type { DefaultError, UseMutationResult, UseQueryResult } from '@tanstack/react-query'
import { useMutation, useQuery } from '@tanstack/react-query'
import { dasherize } from 'inflection'
import { observer } from 'mobx-react-lite'
import type { ComponentType } from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'wouter'
import { navigate } from 'wouter/use-browser-location'
import type { z } from 'zod'

import type { CollapsibleContentSectionRef } from '@/components/CollapsibleContentSection'
import { Screen } from '@/components/Screen'
import type { SectionedFormProps } from '@/components/SectionedForm'
import { SectionedForm } from '@/components/SectionedForm'
import type { RouteParams } from '@/configs/routes'
import { theme } from '@/configs/theme'
import { useGlobalState } from '@/hooks/useGlobalState'
import { NotFoundScreen } from '@/screens/404Screen'
import { ActionsSection } from '@/screens/ContentModuleFormScreen/ActionsSection'
import { AddDataSection } from '@/screens/ContentModuleFormScreen/AddDataSection'
import { OptionalSettingsSection } from '@/screens/ContentModuleFormScreen/OptionalSettingsSection'
import { RequiredSettingsSection } from '@/screens/ContentModuleFormScreen/RequiredSettingsSection'
import { StepsSection } from '@/screens/ContentModuleFormScreen/StepsSection'
import type {
  CreateContentModuleParams,
  CreateContentModuleResponse,
  CreateFAQsParams,
  CreateResourceParams,
  CreateResourceResponse,
  CreateStepParams,
  CreateStepResponse,
  DeleteFAQsParams,
  DeleteResourceParams,
  DeleteStepParams,
  GenerateDemographicParams,
  GenerateDemographicResponse,
  GenerateMetadataParams,
  GenerateMetadataResponse,
  GenerateObjectivesParams,
  GenerateObjectivesResponse,
  GetContentModuleResponse,
  GetContentResponse,
  GetESKsResponse,
  GetFAQsResponse,
  GetResourcesResponse,
  GetSponsorTopicsResponse,
  GetStepsResponse,
  UpdateContentModuleParams,
  UpdateContentModuleResponse,
  UpdateContentTextParams,
  UpdateContentTextResponse,
  UpdateFAQsParams,
  UpdateResourceParams,
  UpdateResourceResponse,
  UpdateStepParams,
} from '@/services/api/api.types'
import * as commonClasses from '@/styles/componentCommons.css'
import * as classes from '@/styles/ContentModuleFormScreen.css'
import type {
  FAQFormSchema,
  MetadataFormSchema,
  ModuleFormSchema,
  ResourceFormSchema,
  StepFormSchema,
} from '@/utils/contentModuleForm'
import {
  castContentModuleToZodForm,
  castResourceToZodForm,
  castStepToZodForm,
  useContentModuleForm,
  useFAQForm,
  useMetadataForm,
  useResourceForm,
  useStepForm,
} from '@/utils/contentModuleForm'
import { isFieldsListValid } from '@/utils/forms'
import { getRoutePath } from '@/utils/navigation'

import { TopicSettingsSection } from './TopicSettingsSection'

enum FormSectionId {
  TopicSettings = 'topicSettings',
  AddData = 'addData',
  CMSettings = 'requiredSettings',
  CMOptionalSettings = 'optionalSettings',
  ContentSteps = 'contentSteps',
  Actions = 'actions',
}

export type CMFormSection = {
  isNew: boolean
  taskId?: string
  stepId?: string
  resourceId?: string

  moduleForm: UseFormReturnType<z.infer<ModuleFormSchema>>
  faqForm: UseFormReturnType<z.infer<FAQFormSchema>>
  resourceForm: UseFormReturnType<z.infer<ResourceFormSchema>>
  stepForm: UseFormReturnType<z.infer<StepFormSchema>>
  metadataForm: UseFormReturnType<MetadataFormSchema>

  openModal: (content?: ModalContent) => void
  closeModal: (afterCloseFn?: () => void) => void
  scrollToSection: (section: `${FormSectionId}`, delay?: number) => void

  esksQuery: UseQueryResult<GetESKsResponse, DefaultError>
  topicsQuery: UseQueryResult<GetSponsorTopicsResponse, DefaultError>

  cmQuery: UseQueryResult<GetContentModuleResponse, DefaultError>
  cmContentQuery: UseQueryResult<GetContentResponse, DefaultError>
  cmFAQsQuery: UseQueryResult<GetFAQsResponse, DefaultError>
  cmResourcesQuery: UseQueryResult<Array<GetResourcesResponse[0] & { valid: boolean }>, DefaultError>
  cmCreateMutation: UseMutationResult<CreateContentModuleResponse, DefaultError, CreateContentModuleParams>
  cmUpdateMutation: UseMutationResult<UpdateContentModuleResponse, DefaultError, UpdateContentModuleParams>

  stepsQuery: UseQueryResult<Array<GetStepsResponse[0] & { valid: boolean }>, DefaultError>
  stepContentQuery: UseQueryResult<GetContentResponse, DefaultError>
  stepFAQsQuery: UseQueryResult<GetFAQsResponse, DefaultError>
  stepResourcesQuery: UseQueryResult<Array<GetResourcesResponse[0] & { valid: boolean }>, DefaultError>
  stepCreateMutation: UseMutationResult<CreateStepResponse, DefaultError, CreateStepParams>
  stepDeleteMutation: UseMutationResult<unknown, DefaultError, DeleteStepParams>
  stepUpdateMutation: UseMutationResult<unknown, DefaultError, UpdateStepParams>

  faqsCreateMutation: UseMutationResult<unknown, DefaultError, CreateFAQsParams>
  faqsUpdateMutation: UseMutationResult<unknown, DefaultError, UpdateFAQsParams>
  faqsDeleteMutation: UseMutationResult<unknown, DefaultError, DeleteFAQsParams>

  resourceCreateMutation: UseMutationResult<CreateResourceResponse, DefaultError, CreateResourceParams>
  resourceUpdateMutation: UseMutationResult<UpdateResourceResponse, DefaultError, UpdateResourceParams>
  resourceDeleteMutation: UseMutationResult<unknown, DefaultError, DeleteResourceParams>

  contentUpdateMutation: UseMutationResult<UpdateContentTextResponse, DefaultError, UpdateContentTextParams>

  generateMetadataMutation: UseMutationResult<GenerateMetadataResponse, DefaultError, GenerateMetadataParams>
  generateObjectivesMutation: UseMutationResult<GenerateObjectivesResponse, DefaultError, GenerateObjectivesParams>
  generateDemographicMutation: UseMutationResult<GenerateDemographicResponse, DefaultError, GenerateDemographicParams>
}

type ModalContent = {
  ModalProps?: ModalProps
  Component: ComponentType<any>
  ComponentProps?: object
  title?: string
  subtitle?: string
}

export const ContentModuleFormScreen = observer(function ContentModuleFormScreen() {
  const params = useParams<RouteParams['contentModuleFormEdit'] | RouteParams['contentModuleFormNew']>()

  const { api } = useGlobalState()

  const { data: moduleData } = useQuery({
    queryKey: ['module', params.id],
    queryFn: () => api.getContentModule({ task_id: params.id! }),
    enabled: !!params.id,
  })
  const { form: moduleForm } = useContentModuleForm({
    debug: true,
    initialData: moduleData,
  })
  const { form: faqForm } = useFAQForm({ debug: true })
  const { form: resourceForm } = useResourceForm({ debug: true })
  const { form: stepForm } = useStepForm({ debug: true })
  const metadataForm = useMetadataForm({ debug: true })

  const taskId = params.id
  const isNew = useMemo(() => !params.id, [params.id])
  const stepId = useMemo(() => stepForm.getValues().stepId ?? undefined, [stepForm])
  const resourceId = useMemo(() => resourceForm.getValues().resourceId ?? undefined, [resourceForm])

  const [modalOpen, modal] = useDisclosure(false)
  const [modalContent, setModalContent] = useState<ModalContent[]>([])
  const modalScrollPositions = useRef<{ [key: string]: number }>({})
  const modalScrollAreaViewport = useRef<HTMLDivElement>(null)
  const ModalComponent = useMemo(() => modalContent[0]?.Component, [modalContent]) as ComponentType<CMFormSection>
  const ModalScrollArea = useCallback(
    (props: ScrollAreaProps) => (
      <ScrollArea.Autosize {...props} viewportRef={modalScrollAreaViewport} type="always" scrollbarSize={16} />
    ),
    [],
  )

  const sectionRefs = {
    [FormSectionId.TopicSettings]: useRef<CollapsibleContentSectionRef>(undefined),
    [FormSectionId.AddData]: useRef<CollapsibleContentSectionRef>(undefined),
    [FormSectionId.CMSettings]: useRef<CollapsibleContentSectionRef>(undefined),
    [FormSectionId.CMOptionalSettings]: useRef<CollapsibleContentSectionRef>(undefined),
    [FormSectionId.ContentSteps]: useRef<CollapsibleContentSectionRef>(undefined),
    [FormSectionId.Actions]: useRef<CollapsibleContentSectionRef>(undefined),
  }

  const esksQuery = useQuery({
    queryKey: ['cm', 'esks'],
    queryFn: api.getESKs,
    initialData: [],
  })

  const topicsQuery = useQuery({
    queryKey: ['sponsor', 'topics'],
    queryFn: api.getSponsorTopics,
    initialData: [],
  })

  const cmQuery = useQuery({
    queryKey: ['cm', taskId],
    queryFn: () => api.getContentModule({ task_id: taskId! }),
    enabled: !isNew,
    gcTime: 0,
    retry: false,
  })

  const additionalEndpointsEnabled = useMemo(
    () => !isNew && cmQuery.isSuccess && cmQuery.data.editable !== false,
    [isNew, cmQuery.data, cmQuery.isSuccess],
  )

  const cmResourcesQuery = useQuery({
    queryKey: ['cm', 'resources', taskId],
    queryFn: () => api.getResources({ task_id: taskId! }),
    select: data => data.map(d => ({ ...d, valid: !!d.title && !!d.description && !!d.data })),
    enabled: additionalEndpointsEnabled,
    gcTime: 0,
  })

  const cmContentQuery = useQuery({
    queryKey: ['cm', 'content', taskId],
    queryFn: () => api.getContent({ task_id: taskId! }),
    enabled: additionalEndpointsEnabled,
  })

  const cmFAQsQuery = useQuery({
    queryKey: ['cm', 'faqs', taskId],
    queryFn: () => api.getFAQs({ task_id: taskId! }),
    enabled: additionalEndpointsEnabled,
  })

  const stepsQuery = useQuery({
    queryKey: ['steps', taskId],
    queryFn: () => api.getSteps({ task_id: taskId! }),
    select: data => data.map(d => ({ ...d, valid: !!d.name })),
    enabled: !!taskId && !isNew,
    gcTime: 0,
  })

  const stepContentQuery = useQuery({
    queryKey: ['step', 'content', stepId],
    queryFn: () => api.getContent({ step_id: stepId! }),
    enabled: !!stepId,
  })

  const stepFAQsQuery = useQuery({
    queryKey: ['step', 'faqs', stepId],
    queryFn: () => api.getFAQs({ step_id: stepId! }),
    enabled: !!stepId,
  })

  const stepResourcesQuery = useQuery({
    queryKey: ['step', 'resources', stepId],
    queryFn: () => api.getResources({ step_id: stepId! }),
    select: data => data.map(d => ({ ...d, valid: !!d.title && !!d.description && !!d.data })),
    enabled: !!stepId,
    gcTime: 0,
  })

  const cmCreateMutation = useMutation({
    mutationFn: api.createContentModule,
    onSuccess: data => {
      api.queryClient.invalidateQueries({ queryKey: ['cm', 'list'] })

      navigate(getRoutePath('contentModuleFormEdit', { id: data.taskId }), { replace: true })

      notifications.show({
        title: 'Module Created',
        message: 'The module was successfully created.',
        color: 'green',
        classNames: commonClasses.notificationFilled,
        position: 'bottom-left',
      })

      sectionRefs.requiredSettings.current?.open()
      sectionRefs.optionalSettings.current?.open()
      sectionRefs.contentSteps.current?.open()
    },
    onError: () => {
      notifications.show({
        title: 'Something went wrong.',
        message: 'There was an issue creating the module. Please try again or contact support.',
        color: 'red',
        classNames: commonClasses.notificationFilled,
        position: 'bottom-left',
      })
    },
  })

  const cmUpdateMutation = useMutation({
    mutationFn: api.updateContentModule,
    onSuccess: () => {
      api.queryClient.invalidateQueries({ queryKey: ['cm', 'list'] })
    },
    onError: () => undefined,
  })

  const stepUpdateMutation = useMutation({
    mutationFn: api.updateStep,
    onSuccess: () => {
      stepsQuery.refetch()
    },
    onError: () => undefined,
  })

  const contentUpdateMutation = useMutation({
    mutationFn: api.updateContentText,
    onSuccess: () => {
      cmContentQuery.refetch()
      if (stepId) stepContentQuery.refetch()
    },
    onError: () => undefined,
  })

  const faqsCreateMutation = useMutation({
    mutationFn: api.createFAQs,
    onSuccess: () => {
      cmFAQsQuery.refetch()
      if (stepId) stepFAQsQuery.refetch()

      notifications.show({
        title: 'FAQ Created',
        message: 'The faq was successfully created.',
        color: 'green',
        classNames: commonClasses.notificationFilled,
        position: 'bottom-left',
      })
    },
    onError: () => undefined,
  })

  const faqsUpdateMutation = useMutation({
    mutationFn: api.updateFAQs,
    onSuccess: () => {
      if (!stepId) cmFAQsQuery.refetch()
      if (stepId) stepFAQsQuery.refetch()

      notifications.show({
        title: 'FAQ Updated',
        message: 'The faq was successfully updated.',
        color: 'green',
        classNames: commonClasses.notificationFilled,
        position: 'bottom-left',
      })
    },
    onError: () => undefined,
  })

  const faqsDeleteMutation = useMutation({
    mutationFn: api.deleteFAQs,
    onSuccess: () => {
      cmFAQsQuery.refetch()
      if (stepId) stepFAQsQuery.refetch()

      notifications.show({
        title: 'FAQ Deleted',
        message: 'The FAQ was successfully deleted.',
        color: 'green',
        classNames: commonClasses.notificationFilled,
        position: 'bottom-left',
      })
    },
    onError: () => undefined,
  })

  const resourceCreateMutation = useMutation({
    mutationFn: api.createResource,
    onSuccess: async data => {
      const resource = await (async function () {
        let resources: GetResourcesResponse = []

        if (!stepId) {
          resources = (await cmResourcesQuery.refetch()).data ?? []
        } else {
          resources = (await stepResourcesQuery.refetch()).data ?? []
        }

        return resources.find(r => r.resourceId === data.resourceId)
      })()

      if (resource) {
        resourceForm.setValues(castResourceToZodForm(resource))
      }
    },
    onError: () => undefined,
  })

  const resourceUpdateMutation = useMutation({
    mutationFn: api.updateResource,
    onSuccess: async (_data, vars) => {
      const resource = await (async function () {
        let resources: GetResourcesResponse = []

        if (!stepId) {
          resources = (await cmResourcesQuery.refetch()).data ?? []
        } else {
          resources = (await stepResourcesQuery.refetch()).data ?? []
        }

        return resources.find(r => r.resourceId === vars.resource_id)
      })()

      if (resource) {
        resourceForm.setValues(castResourceToZodForm(resource))
        resourceForm.resetDirty()
      }
    },
    onError: () => undefined,
  })

  const resourceDeleteMutation = useMutation({
    mutationFn: api.deleteResource,
    onSuccess: () => {
      cmResourcesQuery.refetch()
      if (stepId) stepResourcesQuery.refetch()

      notifications.show({
        title: 'Resource Deleted',
        message: 'The Resource was successfully deleted.',
        color: 'green',
        classNames: commonClasses.notificationFilled,
        position: 'bottom-left',
      })
    },
    onError: () => undefined,
  })

  const stepCreateMutation = useMutation({
    mutationFn: api.createStep,
    onSuccess: async data => {
      notifications.show({
        title: 'Section Created',
        message: 'The section was successfully created.',
        color: 'green',
        classNames: commonClasses.notificationFilled,
        position: 'bottom-left',
      })

      const steps = await stepsQuery.refetch()

      const step = steps.data?.find(step => step.stepId === data.stepId)

      if (step) {
        stepForm.setValues(castStepToZodForm(step))
      }
    },
    onError: () => {
      notifications.show({
        title: 'Something went wrong.',
        message: 'There was an issue creating the section. Please try again or contact support.',
        color: 'red',
        classNames: commonClasses.notificationFilled,
        position: 'bottom-left',
      })
    },
  })

  const stepDeleteMutation = useMutation({
    mutationFn: api.deleteStep,
    onSuccess: (_data, { showToast = true }) => {
      api.queryClient.invalidateQueries({ queryKey: ['steps', taskId] })

      if (showToast) {
        notifications.show({
          title: 'Section Deleted',
          message: 'The section was successfully deleted.',
          color: 'green',
          classNames: commonClasses.notificationFilled,
          position: 'bottom-left',
        })
      }
    },
    onError: () => undefined,
  })

  const generateMetadataMutation = useMutation({
    mutationFn: api.generateMetadata,
    onMutate: vars => {
      if ('task_id' in vars) {
        metadataForm.setFieldValue('module', undefined)
      } else if ('step_id' in vars) {
        metadataForm.setFieldValue('step', undefined)
      } else if ('resource_id' in vars) {
        metadataForm.setFieldValue('resource', undefined)
      }
    },
    onSuccess: (data, vars) => {
      if ('task_id' in vars) {
        metadataForm.setFieldValue('module', data.generated[0])
      } else if ('step_id' in vars) {
        metadataForm.setFieldValue('step', data.generated[0])
      } else if ('resource_id' in vars) {
        metadataForm.setFieldValue('resource', data.generated[0])
      }
    },
    onError: () => undefined,
  })

  const generateDemographicMutation = useMutation({
    mutationFn: api.generateDemographic,
    onMutate: vars => {
      if ('task_id' in vars) {
        metadataForm.setFieldValue('demographic', undefined)
      }
    },
    onSuccess: (data, vars) => {
      if ('task_id' in vars) {
        metadataForm.setFieldValue('demographic', data.demographic)
      }
    },
    onError: () => undefined,
  })

  const generateObjectivesMutation = useMutation({
    mutationFn: api.generateObjectives,
    onMutate: vars => {
      if ('task_id' in vars) {
        metadataForm.setFieldValue('sectionObjectives', undefined)
      }
    },
    onSuccess: (data, vars) => {
      if ('task_id' in vars) {
        metadataForm.setFieldValue('sectionObjectives', data.objectives)
      }
    },
    onError: () => undefined,
  })

  useEffect(() => {
    if (!cmQuery.isSuccess || !stepsQuery.isSuccess) return

    moduleForm.initialize(castContentModuleToZodForm(cmQuery.data))
    moduleForm.resetDirty()

    if (stepsQuery.data?.length) {
      generateMetadataMutation?.mutate({ task_id: taskId!, number_of_options: 1 })
      generateDemographicMutation?.mutate({ task_id: taskId! })
      generateObjectivesMutation?.mutate({ task_id: taskId! })
    }
  }, [cmQuery.data, cmQuery.isFetched, cmQuery.isSuccess, stepsQuery.data, stepsQuery.isSuccess, stepsQuery.isFetched])

  const openModal = useCallback((content?: ModalContent) => {
    if (content) {
      setModalContent(prevState => {
        const currentModal = prevState[0]

        if (currentModal) {
          modalScrollPositions.current[dasherize(currentModal.title ?? '')] =
            modalScrollAreaViewport.current?.scrollTop ?? 0
        }

        return [content, ...prevState]
      })

      setTimeout(() => modalScrollAreaViewport.current?.scrollTo({ top: 0, behavior: 'instant' }), 50)

      modal.open()
    }
  }, [])

  const closeModal: CMFormSection['closeModal'] = useCallback(
    afterCloseFn => {
      const shouldCompletelyClose = modalContent.length === 1

      if (shouldCompletelyClose) {
        modal.close()
        modalScrollPositions.current = {}
      }
      setTimeout(
        () => {
          let nextModal: ModalContent | undefined

          setModalContent(prevState => {
            const [_current, ...rest] = prevState
            nextModal = rest[0]
            return rest
          })

          afterCloseFn?.()

          const nextModalPosY = modalScrollPositions.current[dasherize(nextModal?.title ?? '')] ?? 0

          setTimeout(() => {
            modalScrollAreaViewport.current?.scrollTo({ top: nextModalPosY, behavior: 'instant' })
          }, 50)
        },
        shouldCompletelyClose ? 300 : 0,
      )
    },
    [modalContent],
  )

  const scrollToSection = useCallback((section: `${FormSectionId}`, delay?: number) => {
    setTimeout(() => sectionRefs[section].current?.scrollIntoView(), delay ?? 0)
  }, [])

  const commonData = { taskId, isNew, stepId, resourceId }
  const forms = { moduleForm, faqForm, resourceForm, stepForm, metadataForm }
  const controls = useMemo(() => ({ openModal, closeModal, scrollToSection }), [openModal, closeModal, scrollToSection])
  const operations = useMemo(
    () => ({
      cmQuery,
      cmContentQuery,
      cmFAQsQuery,
      cmResourcesQuery,
      esksQuery,
      stepsQuery,
      cmCreateMutation,
      cmUpdateMutation,
      contentUpdateMutation,
      faqsCreateMutation,
      faqsUpdateMutation,
      faqsDeleteMutation,
      resourceCreateMutation,
      resourceUpdateMutation,
      resourceDeleteMutation,
      stepCreateMutation,
      stepDeleteMutation,
      stepFAQsQuery,
      stepResourcesQuery,
      stepUpdateMutation,
      stepContentQuery,
      generateMetadataMutation,
      generateObjectivesMutation,
      generateDemographicMutation,
      topicsQuery,
    }),
    [
      cmQuery,
      cmContentQuery,
      cmFAQsQuery,
      esksQuery,
      stepsQuery,
      cmResourcesQuery,
      cmCreateMutation,
      cmUpdateMutation,
      contentUpdateMutation,
      faqsCreateMutation,
      faqsUpdateMutation,
      faqsDeleteMutation,
      resourceCreateMutation,
      resourceUpdateMutation,
      resourceDeleteMutation,
      stepCreateMutation,
      stepDeleteMutation,
      stepFAQsQuery,
      stepResourcesQuery,
      stepUpdateMutation,
      stepContentQuery,
      generateMetadataMutation,
      generateObjectivesMutation,
      generateDemographicMutation,
      topicsQuery,
    ],
  )

  const sections = useMemo(
    () =>
      [
        {
          id: FormSectionId.TopicSettings,
          sectionRef: sectionRefs.topicSettings,
          collapsible: false,
          defaultCollapsed: false,
          title: 'Designate a Topic',
          subtitle:
            'Select the Topic for your Content Module and it will be automatically assigned to the Expert Sidekick for that Topic.',
          ContentElement: <TopicSettingsSection {...commonData} {...controls} {...forms} {...operations} />,
          valid: isFieldsListValid(moduleForm, ['selectEsk', 'categories', 'mappings']),
        },
        {
          id: FormSectionId.AddData,
          sectionRef: sectionRefs.addData,
          collapsible: !isNew,
          defaultCollapsed: false,
          title: 'Add Primary Content',
          subtitle:
            'Upload the primary content file for this module or choose to enter your content manually. You will have the option to add supplemental files later.',
          ContentElement: <AddDataSection {...commonData} {...controls} {...forms} {...operations} />,
          valid: !!cmContentQuery.data,
          hidden: true,
        },
        {
          id: FormSectionId.ContentSteps,
          sectionRef: sectionRefs.contentSteps,
          collapsible: !isNew,
          defaultCollapsed: false,
          title: 'Add Content',
          subtitle:
            'Build your Content Module by populating one section at a time. You will be able to add additional sections.',
          ContentElement: <StepsSection {...commonData} {...controls} {...forms} {...operations} />,
          valid:
            isFieldsListValid(moduleForm, ['presentationMode']) &&
            !!stepsQuery.data?.length &&
            !!stepsQuery.data?.every(step => step.valid),
        },
        {
          id: FormSectionId.CMSettings,
          sectionRef: sectionRefs.requiredSettings,
          collapsible: !isNew,
          defaultCollapsed: isNew,
          title: 'Describe Module',
          subtitle: 'Describe module and how you want users to experience it.',
          ContentElement: (
            <RequiredSettingsSection
              {...commonData}
              {...controls}
              {...forms}
              {...operations}
              stepId={stepsQuery.data?.[0]?.stepId}
            />
          ),
          valid: isFieldsListValid(moduleForm, [
            'name',
            'shortDescription',
            'demographic',
            'sectionObjectives',
            'introduction',
          ]),
        },
        {
          id: FormSectionId.CMOptionalSettings,
          sectionRef: sectionRefs.optionalSettings,
          hidden: true, // stepsQuery.data?.length === 1 && !cmFAQsQuery.data?.length && !cmResourcesQuery.data?.length,
          collapsible: !isNew,
          defaultCollapsed: isNew,
          title: 'Add Module FAQs and Supplemental Information',
          subtitle: 'Submit optional information for the module.',
          ContentElement: <OptionalSettingsSection {...commonData} {...controls} {...forms} {...operations} />,
        },
        {
          id: FormSectionId.Actions,
          sectionRef: sectionRefs.actions,
          hidden: isNew,
          collapsible: false,
          ContentElement: <ActionsSection {...commonData} {...controls} {...forms} {...operations} />,
        },
      ] satisfies SectionedFormProps['sections'],
    [isNew, moduleForm, stepsQuery.data, cmContentQuery.data],
  )

  const isModalCentered = useMediaQuery('(min-width: 1921px)')
  const { authenticationStore } = useGlobalState()
  const { user } = authenticationStore

  if (
    !isNew &&
    (cmQuery.isError ||
      (cmQuery.isSuccess && cmQuery.data.editable === false && !cmQuery.data.visibleToMsk && !user?.isSponsorAdmin))
  ) {
    return <NotFoundScreen />
  }
  return (
    <Screen
      title={isNew ? 'New Module' : ['Edit Module', cmQuery.data?.title].filter(Boolean).join(': ')}
      icon={IconHexagons}
      description="Add a module to expand the Knowledge Base of the Expert Sidekick you select below. You'll start by identifying a Topic and Expert Sidekick. Then you'll add your content and choose your settings.">
      <SectionedForm sections={sections} />

      <Modal
        opened={modalOpen}
        centered
        trapFocus
        radius="md"
        padding="lg"
        closeOnEscape={false}
        closeOnClickOutside={false}
        withCloseButton={false}
        size={theme.rem(1200)}
        onClose={closeModal}
        transitionProps={{ duration: 300 }}
        scrollAreaComponent={ModalScrollArea}
        title={
          modalContent[0]?.title && (
            <Stack gap={0}>
              <Text size="xl" fw={600}>
                {modalContent[0]?.title}
              </Text>

              {!!modalContent[0]?.subtitle && (
                <Text size="md" c="dimmed">
                  {modalContent[0].subtitle}
                </Text>
              )}
            </Stack>
          )
        }
        classNames={{
          inner: theme.cx(!isModalCentered && classes.modalInner),
          body: commonClasses.stickyModalFooterBody,
        }}>
        {!!ModalComponent && (
          <ModalComponent
            {...commonData}
            {...controls}
            {...forms}
            {...operations}
            {...modalContent[0]?.ComponentProps}
          />
        )}
      </Modal>
    </Screen>
  )
})
