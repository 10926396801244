import { Group, Paper, Stack, Text } from '@mantine/core'

import { formatChartDate } from '@/utils/analytics'

export type ConversationTooltipProps = {
  label: string
  percentage: number
  payload: any[]
}

function getTooltipText(entry: any, percentage: number): string {
  switch (entry.name) {
    case 'allConversations':
      return `${percentage.toFixed(1)}% of conversations that day`
    case 'total':
      return `${entry.value} conversations`
    case 'unique':
      return `${entry.value} unique members`
    default:
      return `${entry.value} ${entry.name}`
  }
}

export function ConversationByDateTooltip({ label, percentage, payload }: ConversationTooltipProps) {
  return (
    <Paper p="sm" withBorder>
      <Text fw={500} mb={5}>
        {formatChartDate(label, true)}
      </Text>
      <Stack gap="xs">
        {payload.map((entry: any) => (
          <Group key={entry.name} gap="sm">
            <div style={{ width: 10, height: 10, backgroundColor: entry.color, borderRadius: '50%' }} />
            <Text size="sm">{getTooltipText(entry, percentage)}</Text>
          </Group>
        ))}
      </Stack>
    </Paper>
  )
}

export function ConversationTooltip({ label, percentage, payload }: ConversationTooltipProps) {
  return (
    <Paper p="sm" withBorder>
      <Text fw={500} mb={5}>
        {label}
      </Text>
      <Stack gap="xs">
        {payload.map((entry: any) => (
          <Group key={entry.name} gap="sm">
            <div style={{ width: 10, height: 10, backgroundColor: entry.color, borderRadius: '50%' }} />
            <Text size="sm">{getTooltipText(entry, percentage)}</Text>
          </Group>
        ))}
      </Stack>
    </Paper>
  )
}
