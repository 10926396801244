import type { LineChartSeries } from '@mantine/charts'
import { BarChart, LineChart } from '@mantine/charts'
import { Group, Paper, Stack, Tabs, Text, Title } from '@mantine/core'
import { IconHexagons, IconMessage, IconMessageCirclePlus, IconMessages, IconUser } from '@tabler/icons-react'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { ResponsiveContainer } from 'recharts'

import { colors } from '@/configs/colors'
import { useGlobalState } from '@/hooks/useGlobalState'
import { formatChartDate, getRelativeDateRange } from '@/utils/analytics'

import { ConversationTooltip } from './ConversationByDateTooltip'
import { StatInfoGraphic } from './StatInfoGraphic'
import TabChartWrapper from './TabChartWrapper'

const TabKeys = {
  cmConversationBarChart: 'cmConversationBarChart',
  activeUsersLineChart: 'activeUsersLineChart',
} as const

const conversationSeries = [
  {
    name: 'unique',
    label: 'Unique Conversations',
    color: colors.chartColors[1],
  },
  {
    name: 'total',
    label: 'Total Conversations',
    color: colors.chartColors[0],
  },
]

const activeMembersSeries: LineChartSeries[] = [
  {
    name: 'total',
    label: 'Total Active Users',
    color: colors.chartColors[0],
  },
]

export default function InsightsCharts() {
  const { api } = useGlobalState()

  const [activeTab, setActiveTab] = useState<string | null>(TabKeys.activeUsersLineChart)
  const [barChartHeight, setBarChartHeight] = useState(300)

  const {
    data: activeMembersData,
    isLoading: activeMembersLoading,
    error: activeMembersError,
  } = useQuery({
    queryKey: ['analytics', 'activeMembersByDate'],
    queryFn: () => api.getActiveMembersByDate({ ...getRelativeDateRange(30) }),
  })

  const {
    data: allConversationsPerCmData,
    isLoading: allConversationsPerCmLoading,
    error: allConversationsPerCmError,
  } = useQuery({
    queryKey: ['analytics', 'allConversationsPerCm'],
    queryFn: () => api.getAllConversationsPerCm({ ...getRelativeDateRange(30) }),
  })

  useEffect(() => {
    if (allConversationsPerCmData?.data.length) {
      setBarChartHeight(allConversationsPerCmData.data.length * 40)
    }
  }, [allConversationsPerCmData?.data.length])

  const getAverageConversationLength = (): number => allConversationsPerCmData?.stats.averageConversationLength ?? 0

  return (
    <Stack>
      <Paper radius="md" p="lg" shadow="sm" px="xl">
        <Group justify="space-between" mb="md" align="top">
          <Group gap="xl">
            <StatInfoGraphic
              icon={IconHexagons}
              label="Modules"
              value={allConversationsPerCmData?.data.length ?? 0}
              tooltipText="Total number of Content Modules with conversations"
            />
            <StatInfoGraphic
              icon={IconMessages}
              label="Conversations"
              value={allConversationsPerCmData?.stats.totalConversations ?? 0}
              tooltipText="The total number of conversations for all content modules"
            />
            <StatInfoGraphic
              icon={IconMessage}
              label="Messages"
              value={allConversationsPerCmData?.stats.totalMessages ?? 0}
              tooltipText="The total number of messages sent by users in all conversations"
            />
            <StatInfoGraphic
              icon={IconMessageCirclePlus}
              label="Unique Conversations"
              value={allConversationsPerCmData?.stats.totalUniqueConversations ?? 0}
              tooltipText="The total number of unique conversations"
            />
          </Group>

          <Group gap="xs" justify="flex-end" align="top">
            <Text size="sm" c="dimmed">
              {formatChartDate(allConversationsPerCmData?.startDate ?? null, true)}
            </Text>
            <Text size="sm" c="dimmed">
              —
            </Text>
            <Text size="sm" c="dimmed">
              {formatChartDate(allConversationsPerCmData?.endDate ?? null, true)}
            </Text>
          </Group>
        </Group>
      </Paper>

      <Paper radius="md" p="lg" shadow="sm" px="xl">
        <Tabs value={activeTab} onChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Tab value={TabKeys.activeUsersLineChart} leftSection={<IconUser size={16} />}>
              Active Members
            </Tabs.Tab>
            <Tabs.Tab value={TabKeys.cmConversationBarChart} leftSection={<IconMessage size={16} />}>
              CM Conversations
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value={TabKeys.activeUsersLineChart} py="md">
            <Title order={6} mb="md">
              Daily Active Members
            </Title>
            <TabChartWrapper
              isActive={activeTab === TabKeys.activeUsersLineChart}
              isLoading={activeMembersLoading}
              error={activeMembersError}>
              <LineChart
                h={300}
                data={activeMembersData?.data ?? []}
                dataKey="date"
                curveType="linear"
                series={activeMembersSeries}
                xAxisProps={{
                  tickFormatter: (date: string) => {
                    return formatChartDate(date)
                  },
                  angle: -45,
                  textAnchor: 'end',
                }}
              />
            </TabChartWrapper>
          </Tabs.Panel>

          <Tabs.Panel value={TabKeys.cmConversationBarChart} py="md">
            <Title order={6} mb="md">
              Conversations with each Content Module
            </Title>
            <TabChartWrapper
              isActive={activeTab === TabKeys.cmConversationBarChart}
              isLoading={allConversationsPerCmLoading}
              error={allConversationsPerCmError}>
              <ResponsiveContainer width="100%" height={barChartHeight}>
                <BarChart
                  data={(allConversationsPerCmData?.data || []).map(entry => ({
                    ...entry,
                    total: entry.total - entry.unique,
                  }))}
                  dataKey="label"
                  type="stacked"
                  orientation="vertical"
                  series={conversationSeries}
                  gridAxis="none"
                  yAxisProps={{
                    interval: 0,
                    tickFormatter: (value: string, _: number) => {
                      const maxChars = 24
                      return value.length > maxChars ? value.substring(0, maxChars - 3) + '...' : value
                    },
                    width: 150,
                  }}
                  tooltipProps={{
                    content: ({ payload, label }) => {
                      if (!payload || payload.length === 0) return null

                      // Find the total value for this date point
                      const totalEntry = payload.find(entry => entry.name === 'total')
                      const uniqueEntry = payload.find(entry => entry.name === 'unique')
                      const totalValue = (totalEntry?.value || 0) + (uniqueEntry?.value || 0)
                      if (totalEntry) {
                        totalEntry.value = totalValue
                      }

                      // Filter out the original 'total' entry and add the modified one
                      const newPayload = [
                        { ...totalEntry, value: totalValue },
                        ...payload.filter(entry => entry.name !== 'total'),
                      ]

                      return <ConversationTooltip label={label} percentage={0} payload={newPayload} />
                    },
                  }}
                  referenceLines={[
                    {
                      y: getAverageConversationLength(),
                      color: 'orange',
                      label: `${Math.round(getAverageConversationLength())}`,
                      strokeDasharray: '5 5',
                      labelPosition: 'right',
                    },
                  ]}
                />
              </ResponsiveContainer>
            </TabChartWrapper>
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </Stack>
  )
}
