import { CompositeChart } from '@mantine/charts'
import type { ComboboxData } from '@mantine/core'
import { Group, Paper, Select, Stack, Tabs, Text, Title } from '@mantine/core'
import { IconEyeExclamation, IconMessage, IconPencil, IconRuler2, IconRulerMeasure } from '@tabler/icons-react'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { ResponsiveContainer } from 'recharts'

import { StatInfoGraphic } from '@/components/StatInfoGraphic'
import { useGlobalState } from '@/hooks/useGlobalState'
import type { GetContentModuleResponse } from '@/services/api/api.types'
import { selectStyles } from '@/styles/Chart.css'
import { conversationsSeries, formatChartDate, getRelativeDateRange } from '@/utils/analytics'

import { ConversationByDateTooltip } from './ConversationByDateTooltip'
import TabChartWrapper from './TabChartWrapper'

const TabKeys = {
  conversationLineChart: 'conversationLineChart',
  messageLineChart: 'messageLineChart',
} as const

export default function ContentModuleInsights() {
  const { api } = useGlobalState()

  const cmQuery = useQuery({
    queryKey: ['cm', 'list'],
    queryFn: api.getContentModules,
    initialData: [],
    refetchInterval: 7 * 1000,
  })

  const [selectedCm, setSelectedCm] = useState<GetContentModuleResponse | null>(null)
  const [activeTab, setActiveTab] = useState<string | null>(TabKeys.conversationLineChart)

  const { data, isLoading, error } = useQuery({
    queryKey: ['analytics', 'cmConversationsByDate', selectedCm?.taskId],
    queryFn: () => api.getCmConversationsByDate({ cm_id: selectedCm?.taskId ?? '', ...getRelativeDateRange(30) }),
    enabled: !!selectedCm?.taskId, // Only run the query when we have a content module taskId
  })

  // Only show published content modules in the dropdown
  const publishedCms = cmQuery.data.filter(cm => cm.status === 'published')

  const cmOptions: ComboboxData = publishedCms.map(({ taskId, name }) => ({
    value: taskId ?? '',
    label: name ?? '',
    disabled: !taskId,
  }))

  useEffect(() => {
    if (publishedCms.length > 0 && !selectedCm) {
      setSelectedCm(publishedCms[0])
    }
  }, [publishedCms])

  return (
    <Stack>
      <Paper radius="md" p="lg" shadow="sm" px="xl">
        <Group justify="space-between" mb="md" align="top">
          <Select
            data={cmOptions}
            label="Content Module"
            placeholder="Select a content module"
            value={selectedCm?.taskId ?? null}
            onChange={value => {
              const cm = cmQuery.data.find(cm => cm.taskId === value)
              setSelectedCm(cm ?? null)
            }}
            miw={300}
            classNames={selectStyles}
          />
          <Stack align="flex-end" gap="md">
            <Group gap="xs">
              <Text size="sm" c="dimmed">
                {formatChartDate(data?.startDate ?? '', true)}
              </Text>
              <Text size="sm" c="dimmed">
                —
              </Text>
              <Text size="sm" c="dimmed">
                {formatChartDate(data?.endDate ?? '', true)}
              </Text>
            </Group>

            <Group gap="xl">
              <StatInfoGraphic
                icon={IconEyeExclamation}
                label="Unique Visitors"
                value={data?.stats.totalUniqueConversations ?? 0}
                tooltipText="Number of unique members who interacted with this content module"
              />

              <StatInfoGraphic
                icon={IconMessage}
                label="Conversations"
                value={data?.stats.totalConversations ?? 0}
                tooltipText="Total number of conversations for this content module"
              />

              <StatInfoGraphic
                icon={IconPencil}
                label="Member Messages"
                value={data?.stats.totalMessages ?? 0}
                tooltipText="Total number of messages sent by members using this content module"
              />

              <StatInfoGraphic
                icon={IconRuler2}
                label="Average Length"
                value={Math.round(data?.stats.averageConversationLength ?? 0)}
                tooltipText="Average length of a conversation using this content module"
              />

              <StatInfoGraphic
                icon={IconRulerMeasure}
                label="Max Length"
                value={data?.stats.maxConversationLength ?? 0}
                tooltipText="Longest conversation using this content module"
              />
            </Group>
          </Stack>
        </Group>
      </Paper>

      <Paper radius="md" p="lg" shadow="sm" px="xl">
        <Tabs value={activeTab} onChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Tab value={TabKeys.conversationLineChart}>Conversations</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value={TabKeys.conversationLineChart}>
            <Group justify="space-between" align="baseline">
              <Title order={6} mb="md" pt="md">
                Number of Conversations over Time
              </Title>
            </Group>

            <TabChartWrapper isActive={activeTab === TabKeys.conversationLineChart} isLoading={isLoading} error={error}>
              <ResponsiveContainer width="100%" height={300}>
                <CompositeChart
                  data={data?.data ?? []}
                  dataKey="date"
                  curveType="linear"
                  series={conversationsSeries}
                  xAxisProps={{
                    tickFormatter: (date: string) => {
                      return formatChartDate(date)
                    },
                    angle: -45,
                    textAnchor: 'end',
                  }}
                  tooltipProps={{
                    content: ({ payload, label }) => {
                      if (!payload || payload.length === 0) return null

                      // Find the total value for this date point
                      const totalValue = payload.find(entry => entry.name === 'total')?.value || 0
                      const allConversationsValue = payload.find(entry => entry.name === 'allConversations')?.value || 1
                      const percentage = (totalValue / allConversationsValue) * 100

                      return <ConversationByDateTooltip label={label} percentage={percentage} payload={payload} />
                    },
                  }}
                />
              </ResponsiveContainer>
            </TabChartWrapper>
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </Stack>
  )
}
