import type { BarChartSeries, LineChartSeries } from '@mantine/charts'
import { BarChart, LineChart } from '@mantine/charts'
import { Group, Paper, Stack, Tabs, Text, Title } from '@mantine/core'
import { IconEyeExclamation, IconPencil } from '@tabler/icons-react'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { ResponsiveContainer } from 'recharts'

import { colors } from '@/configs/colors'
import { useGlobalState } from '@/hooks/useGlobalState'
import { formatChartDate, getRelativeDateRange } from '@/utils/analytics'

import { StatInfoGraphic } from './StatInfoGraphic'
import TabChartWrapper from './TabChartWrapper'

const TabKeys = {
  loginLineChart: 'loginLineChart',
  cmEditLineChart: 'cmEditLineChart',
  cmEditBarChart: 'cmEditBarChart',
} as const

const smeLoginsByDateSeries: LineChartSeries[] = [
  { name: 'total', color: colors.chartColors[0], label: 'Total Visits' },
]

const cmEditsByDateSeries: LineChartSeries[] = [{ name: 'total', color: colors.chartColors[0], label: 'Total Edits' }]

const editsPerCmSeries: BarChartSeries[] = [{ name: 'total', color: colors.chartColors[0], label: 'Total Edits' }]

export default function SmeMetrics() {
  const { api } = useGlobalState()
  const [activeTab, setActiveTab] = useState<string | null>(TabKeys.loginLineChart)
  const [barChartHeight, setBarChartHeight] = useState(300)

  const {
    data: smeLoginsByDate,
    isLoading: smeLoginsByDateLoading,
    error: smeLoginsByDateError,
  } = useQuery({
    queryKey: ['analytics', 'smeLoginsByDate'],
    queryFn: () => api.getSmeLoginsByDate({ ...getRelativeDateRange(30) }),
  })

  const {
    data: cmEditsPerSme,
    isLoading: cmEditsByDateLoading,
    error: cmEditsByDateError,
  } = useQuery({
    queryKey: ['analytics', 'cmEditsPerSme'],
    queryFn: () => api.getCmEditsByDate({ ...getRelativeDateRange(30) }),
  })

  const {
    data: editsPerCm,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['analytics', 'editsPerCm'],
    queryFn: () => api.getEditsPerCm({ ...getRelativeDateRange(30) }),
  })

  useEffect(() => {
    if (editsPerCm?.data.length) {
      setBarChartHeight(editsPerCm.data.length * 40)
    }
  }, [editsPerCm?.data.length])

  return (
    <Stack>
      <Paper radius="md" p="lg" shadow="sm" px="xl">
        <Group justify="space-between" mb="md" align="top">
          <Group gap="xl">
            <StatInfoGraphic
              icon={IconEyeExclamation}
              label="SME Visits"
              value={smeLoginsByDate?.stats.total ?? 0}
              tooltipText="Total number of Subject Matter Expert visits to Portal"
            />
            <StatInfoGraphic
              icon={IconPencil}
              label="CM Edits"
              value={editsPerCm?.stats.total ?? 0}
              tooltipText="Total number of Content Module edits"
            />
          </Group>

          <Group gap="xs" justify="flex-end" align="top">
            <Text size="sm" c="dimmed">
              {formatChartDate(editsPerCm?.startDate ?? '', true)}
            </Text>
            <Text size="sm" c="dimmed">
              —
            </Text>
            <Text size="sm" c="dimmed">
              {formatChartDate(editsPerCm?.endDate ?? '', true)}
            </Text>
          </Group>
        </Group>
      </Paper>

      <Paper radius="md" p="lg" shadow="sm" px="xl">
        <Tabs value={activeTab} onChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Tab value={TabKeys.loginLineChart} leftSection={<IconEyeExclamation size={16} />}>
              SME Visits
            </Tabs.Tab>
            <Tabs.Tab value={TabKeys.cmEditLineChart} leftSection={<IconPencil size={16} />}>
              SME Edits
            </Tabs.Tab>
            <Tabs.Tab value={TabKeys.cmEditBarChart} leftSection={<IconPencil size={16} />}>
              CM Edits
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value={TabKeys.loginLineChart} py="md">
            <Title order={6} mb="md">
              Number of Portal Visits by Subject Matter Experts
            </Title>
            <TabChartWrapper
              isActive={activeTab === TabKeys.loginLineChart}
              isLoading={smeLoginsByDateLoading}
              error={smeLoginsByDateError}>
              <LineChart
                h={300}
                data={smeLoginsByDate?.data ?? []}
                dataKey="date"
                series={smeLoginsByDateSeries}
                curveType="linear"
                xAxisProps={{
                  tickFormatter: (date: string) => {
                    return formatChartDate(date)
                  },
                  angle: -45,
                  textAnchor: 'end',
                }}
              />
            </TabChartWrapper>
          </Tabs.Panel>
          <Tabs.Panel value={TabKeys.cmEditLineChart} py="md">
            <Title order={6} mb="md">
              Number of Edits by Subject Matter Experts
            </Title>
            <TabChartWrapper
              isActive={activeTab === TabKeys.cmEditLineChart}
              isLoading={cmEditsByDateLoading}
              error={cmEditsByDateError}>
              <LineChart
                h={300}
                data={cmEditsPerSme?.data ?? []}
                dataKey="date"
                series={cmEditsByDateSeries}
                curveType="linear"
                xAxisProps={{
                  tickFormatter: (date: string) => {
                    return formatChartDate(date)
                  },
                  angle: -45,
                  textAnchor: 'end',
                }}
              />
            </TabChartWrapper>
          </Tabs.Panel>
          <Tabs.Panel value={TabKeys.cmEditBarChart} py="md">
            <Title order={6} mb="md">
              Total Edits to Each Content Module
            </Title>
            <TabChartWrapper isActive={activeTab === TabKeys.cmEditBarChart} isLoading={isLoading} error={error}>
              <ResponsiveContainer width="100%" height={barChartHeight}>
                <BarChart
                  data={editsPerCm?.data || []}
                  dataKey="label"
                  type="stacked"
                  orientation="vertical"
                  series={editsPerCmSeries}
                  gridAxis="none"
                  yAxisProps={{
                    interval: 0,
                    tickFormatter: (value: string, _: number) => {
                      const maxChars = 24
                      return value.length > maxChars ? value.substring(0, maxChars - 3) + '...' : value
                    },
                    width: 150,
                  }}
                />
              </ResponsiveContainer>
            </TabChartWrapper>
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </Stack>
  )
}
