import * as Sentry from '@sentry/react'
import type { Instance, SnapshotOut } from 'mobx-state-tree'
import { types } from 'mobx-state-tree'

import { Consts } from '@/configs/consts'
import { withRootStore } from '@/services/state/helpers/withRootStore'
import { withSetProp } from '@/services/state/helpers/withSetProp'
import { removeNullishValuesFromObject } from '@/utils/objects'
import { load, save } from '@/utils/storage'

const CONFIG_OVERRIDES_PERSISTENCE_KEY = '@sidekick/config/v1'

export enum ServerEnv {
  Dev = 'dev',
  Test = 'test',
  Stag = 'stag',
  Intrl = 'intrl',
}

export const ConfigStoreModel = types
  .model('ConfigStore')
  .props({
    ready: types.optional(types.boolean, false),
    isDev: types.optional(types.boolean, Consts.isDev),
    isProd: types.optional(types.boolean, Consts.isProd),
    serverBaseUrl: types.optional(types.maybe(types.string), Consts.serverBaseUrl),
    analyticsServerBaseUrl: types.optional(types.maybe(types.string), Consts.analyticsServerBaseUrl),
    posthogKey: types.optional(types.maybe(types.string), Consts.posthogKey),
  })
  .extend(withRootStore)
  .extend(withSetProp)
  .views(self => ({
    get serverEnv() {
      return !!self.serverBaseUrl ? (new URL(self.serverBaseUrl).hostname.split('.')[0] ?? null) : null
    },
  }))
  .actions(self => ({
    async afterAttach() {
      let config: Partial<ImportMeta['env']> = {}

      // load runtime config from server
      const response = await self.rootStore.api.safeRequest(self.rootStore.api.getRuntimeConfig)
      if (response.ok && typeof response.data === 'object') {
        Sentry.setContext('config (runtime)', response.data)
        config = { ...config, ...removeNullishValuesFromObject(response.data) }
      }

      // load overrides from local storage
      const overrides = load(CONFIG_OVERRIDES_PERSISTENCE_KEY) ?? {}
      if (overrides) {
        config = { ...config, ...removeNullishValuesFromObject(overrides) }
      }

      // apply config
      if (config.VITE_SERVER_BASE_URL) {
        self.setProp('serverBaseUrl', config.VITE_SERVER_BASE_URL)
        self.rootStore.api.setBaseUrl(config.VITE_SERVER_BASE_URL)
      }
      if (config.VITE_ANALYTICS_SERVER_BASE_URL) {
        self.setProp('analyticsServerBaseUrl', config.VITE_ANALYTICS_SERVER_BASE_URL)
      }
      if (config.VITE_POSTHOG_KEY) {
        self.setProp('posthogKey', config.VITE_POSTHOG_KEY)
      }

      self.setProp('ready', true)
    },
    saveConfigOverride(config: Partial<ImportMeta['env']>) {
      save(CONFIG_OVERRIDES_PERSISTENCE_KEY, { ...(load(CONFIG_OVERRIDES_PERSISTENCE_KEY) || {}), ...config })
    },
  }))

export interface ConfigStore extends Instance<typeof ConfigStoreModel> {}
export interface ConfigStoreSnapshot extends SnapshotOut<typeof ConfigStoreModel> {}
