import { useForm } from '@mantine/form'
import { modals } from '@mantine/modals'
import { useMutation, useQuery } from '@tanstack/react-query'
import { zodResolver } from 'mantine-form-zod-resolver'
import { useEffect, useRef } from 'react'
import { z } from 'zod'

import { useGlobalState } from '@/hooks/useGlobalState'
import type {
  CreateFAQsParams,
  CreateResourceParams,
  GenerateDemographicResponse,
  GenerateMetadataResponse,
  GenerateObjectivesResponse,
  GetContentModuleResponse,
  GetFAQsResponse,
  GetResourcesResponse,
  GetStepsResponse,
  UpdateContentModuleParams,
  UpdateFAQsParams,
  UpdateStepParams,
} from '@/services/api/api.types'
import { logForm } from '@/utils/logs'

import { load, remove, save } from './storage'

/**
 *
 * Schemas
 *
 */
export const contentSchema = z.object({
  // contentId: z.nullable(z.string()), // not needed for now
  file: z.nullable(z.instanceof(File)),
  text: z.string().min(1, "The content can't be blank."), // content: for GET; text: for POST
})

export const moduleSchema = z
  .object({
    taskId: z.nullable(z.string()), // task_id
    name: z.string().min(1), // name
    shortDescription: z.string().min(1), // msk introduction
    presentationMode: z.enum(['sequential', 'member_directed']), // step presentation mode - TODO: cast to boolean for api `are_step_sequential
    access: z.enum(['restricted', 'open']), // privacy setting - NOT IMPLEMENTED
    expirationDate: z.date().optional(), // expiration date - NOT IMPLEMENTED
    dueDate: z.date().optional(), // due date - NOT IMPLEMENTED
    categories: z.array(z.string()).min(1), // categories (Topic for the module)
    mappings: z.array(z.string()).min(1), // mappings (Sub-topics for the module)
    selectEsk: z.string(), // esk
    content: contentSchema, // content
    demographic: z.string().min(1), // target audience
    priority: z.number().optional(), // priority - NOT IMPLEMENTED
    sectionObjectives: z.string().min(1), // objectives
    titleImage: z.nullable(z.string().min(1)),
    introduction: z.string().min(1),
    visibleToMsk: z.boolean(),
    allowedForAllSponsors: z.boolean(),
  })
  .partial({ expirationDate: true, dueDate: true })

export const faqSchema = z.object({
  faqId: z.nullable(z.string()),
  question: z.string().min(1),
  answer: z.string().min(1, "Answer can't be empty."),
})

export const resourceSchema = z.object({
  resourceId: z.nullable(z.string()),
  data: z.string().min(1),
  dataUrl: z.string().url(),
  file: z.nullable(z.instanceof(File)),
  s3File: z.nullable(z.string()),
  fileName: z.nullable(z.string()),
  type: z.enum(['url', 'image', 'video', 'text']),
  keywords: z.array(z.string()),
  title: z.string().min(1),
  description: z.string().min(1),
})

export const stepSchema = z.object({
  stepId: z.nullable(z.string()),
  content: contentSchema,
  name: z.string().min(1),
  titleImage: z.nullable(z.string().min(1)),
  sectionObjectives: z.string().min(1),
  introduction: z.nullable(z.string()), // esk introduction
  shortDescription: z.string().min(1), // esk reminder
  title: z.nullable(z.string()), // msk reminder
  // deliveryMode: z.nativeEnum(DeliveryMode),
  // access: z.enum(['restricted', 'open']), // privacy setting - NOT IMPLEMENTED
})

/**
 *
 * Form Hooks
 *
 */
export function useContentModuleForm(opts?: { debug?: boolean; initialData?: GetContentModuleResponse }) {
  const shouldCache = useRef(false)

  const { api, uiStore } = useGlobalState()

  const cmQuery = useQuery({
    queryKey: ['cm', 'list'],
    queryFn: api.getContentModules,
    initialData: [],
    refetchInterval: 7 * 1000,
  })

  const cmChangeStatusMutation = useMutation({
    mutationFn: api.changeContentModuleStatus,
    onSuccess: (d, _vars) => {
      const success = 'status' in d && d.status
      const error = 'errors' in d

      if (success) {
        api.queryClient.invalidateQueries({
          queryKey: ['cm', 'list'],
        })

        setTimeout(() => {
          cmQuery.refetch()
        }, 1000)
      } else if (error) {
        console.error('Error updating module status', d)
      }
    },
    onError: error => {
      console.error('Error updating module status', error)
    },
  })

  const form = useForm<z.infer<ModuleFormSchema>>({
    mode: 'controlled',
    validate: zodResolver(moduleSchema),
    validateInputOnChange: true,
    initialValues: castContentModuleToZodForm(opts?.initialData),
    onValuesChange(values) {
      if (opts?.debug) {
        logForm('Content Module', values)
      }

      saveToCache()
    },
  })

  function loadFromCache() {
    const values = form.getValues()

    if (values.taskId) {
      return load(`@sidekick/forms/cm/${values.taskId}`)
    } else {
      return null
    }
  }

  function saveToCache() {
    const values = form.getValues()

    if (shouldCache.current && values.taskId) {
      save(`@sidekick/forms/cm/${values.taskId}`, values)
    }
  }

  function clearCache() {
    const taskId = form.getValues().taskId

    if (taskId) {
      remove(`@sidekick/forms/cm/${taskId}`)
    }
  }

  useEffect(() => {
    const isNewCM = uiStore.isNewContentModule

    const deleteNewCM = () => {
      if (isNewCM) {
        uiStore.setIsNewContentModule(false)
        // Delete the new content module from the database
        const taskId = form.getValues().taskId
        if (taskId) {
          cmChangeStatusMutation.mutate({
            task_id: taskId,
            status: 'removed',
          })
        }
      }
    }

    if (form.isDirty() && form.isTouched()) {
      shouldCache.current = true
      saveToCache()

      uiStore.setRouterLocationChangeFn(async () => {
        let resolveFn: ((v: boolean) => void) | undefined

        const confirmation = new Promise<boolean>(resolve => (resolveFn = resolve))

        modals.openConfirmModal({
          title: 'Unsaved Changes',
          centered: true,
          children: `You have unsaved changes. Are you sure you want to discard them?`,
          labels: { confirm: 'Discard Changes', cancel: 'Cancel' },
          confirmProps: { color: 'red' },
          groupProps: { mt: 'xl' },
          onConfirm: () => {
            clearCache()
            resolveFn?.(true)
            deleteNewCM()
          },
          onCancel: () => resolveFn?.(false),
        })

        const confirmed = await confirmation

        return confirmed
      })
    } else if (isNewCM) {
      uiStore.setRouterLocationChangeFn(async () => {
        let resolveFn: ((v: boolean) => void) | undefined

        const confirmation = new Promise<boolean>(resolve => (resolveFn = resolve))

        modals.openConfirmModal({
          title: 'Unsaved New Content Module',
          centered: true,
          children: `Leaving this page will discard your new content module. Are you sure you want to continue?`,
          labels: { confirm: 'Discard Content Module', cancel: 'Cancel' },
          confirmProps: { color: 'red' },
          groupProps: { mt: 'xl' },
          onConfirm: () => {
            clearCache()
            resolveFn?.(true)
            deleteNewCM()
          },
          onCancel: () => resolveFn?.(false),
        })

        const confirmed = await confirmation

        return confirmed
      })
    }

    return () => {
      uiStore.setRouterLocationChangeFn(undefined)
    }
  }, [form.isDirty(), form.isTouched()])

  useEffect(() => {
    if (form.initialized) {
      const cached = loadFromCache()

      if (cached) {
        modals.openConfirmModal({
          title: 'Restore Unsaved Changes',
          centered: true,
          children: `There are unsaved changes from your previous session. Would you like to restore them?`,
          labels: { confirm: 'Restore Changes', cancel: 'Cancel' },
          groupProps: { mt: 'xl' },
          onConfirm: () => {
            form.setValues(cached)
            form.setTouched({ taskId: true })
          },
          onCancel: () => clearCache(),
        })
      }
    }
  }, [form.initialized])

  return { form, schema: moduleSchema, contentSchema }
}

export function useFAQForm(opts?: { debug?: boolean }) {
  const form = useForm<z.infer<FAQFormSchema>>({
    mode: 'controlled',
    validate: zodResolver(faqSchema),
    validateInputOnChange: true,
    initialValues: castFAQToZodForm(),
    onValuesChange(values) {
      if (opts?.debug) {
        logForm('FAQ', values)
      }
    },
  })

  return { form, schema: faqSchema }
}

export function useResourceForm(opts?: { debug?: boolean }) {
  const form = useForm<z.infer<ResourceFormSchema>>({
    mode: 'controlled',
    validate: zodResolver(resourceSchema),
    validateInputOnChange: true,
    initialValues: castResourceToZodForm(),
    onValuesChange(values) {
      if (opts?.debug) {
        logForm('Resource', values)
      }
    },
  })

  return { form, schema: resourceSchema }
}

export function useStepForm(opts?: { debug?: boolean }) {
  const form = useForm<z.infer<StepFormSchema>>({
    mode: 'controlled',
    validate: zodResolver(stepSchema),
    validateInputOnChange: true,
    initialValues: castStepToZodForm(),
    onValuesChange(values) {
      if (opts?.debug) {
        logForm('Step', values)
      }
    },
  })

  return { form, schema: stepSchema }
}

export function useMetadataForm(opts?: { debug?: boolean }) {
  return useForm<MetadataFormSchema>({
    mode: 'controlled',
    onValuesChange(values) {
      if (opts?.debug) {
        logForm('Metadata', values)
      }
    },
  })
}

/**
 *
 * Data Transformation
 *
 */
export function castContentModuleToZodForm(data?: Partial<GetContentModuleResponse>): z.infer<ModuleFormSchema> {
  return {
    taskId: data?.taskId ?? null,
    name: data?.name ?? '',
    shortDescription: data?.shortDescription ?? '',
    presentationMode: data?.areStepSequential
      ? moduleSchema.shape.presentationMode.enum.sequential
      : moduleSchema.shape.presentationMode.enum.member_directed,
    selectEsk: data?.selectEsk ?? '',
    categories: data?.categories ?? [],
    mappings: data?.mappings ?? [],
    content: { text: '', file: null },
    demographic: data?.demographic ?? '',
    priority: data?.priority ?? undefined,
    visibleToMsk: data?.visibleToMsk ?? false,
    allowedForAllSponsors: data?.allowedForAllSponsors ?? false,
    sectionObjectives: data?.sectionObjectives ?? '',
    titleImage: data?.titleImage ?? '',
    introduction: data?.introduction ?? '',
    // mock
    access: 'open',
  }
}

export function castResourceToZodForm(data?: Partial<GetResourcesResponse[0]>): z.infer<ResourceFormSchema> {
  return {
    resourceId: data?.resourceId ?? null,
    title: data?.title ?? '',
    description: data?.description ?? '',
    file: null,
    data: data?.data ?? '',
    dataUrl: data?.type === 'url' ? (data?.data ?? '') : '',
    fileName: data?.fileName ?? '',
    type: data?.type ?? 'text',
    keywords: data?.keywords ?? [],
    s3File: data?.s3File ?? null,
  }
}

export function castFAQToZodForm(data?: Partial<GetFAQsResponse['0']>): z.infer<FAQFormSchema> {
  return {
    faqId: data?.faqId ?? null,
    question: data?.question ?? '',
    answer: data?.answer ?? '',
  }
}

export function castZodFormToContentModule(
  data: z.infer<ModuleFormSchema>,
): Omit<UpdateContentModuleParams, 'task_id'> {
  return {
    name: data.name,
    title: data.name,
    short_description: data.shortDescription,
    are_step_sequential: data.presentationMode === undefined ? undefined : data.presentationMode === 'sequential',
    select_esk: data.selectEsk,
    categories: data.categories,
    mappings: data.mappings,
    long_description: 'NOT_IMPLEMENTED_FRONT_END',
    demographic: data.demographic,
    visible_to_msk: data.visibleToMsk,
    allowed_for_all_sponsors: data.allowedForAllSponsors,
    section_objectives: data.sectionObjectives,
    title_image: data.titleImage,
    introduction: data.introduction,
  }
}

export function castZodFormToResource(
  data: z.infer<ResourceFormSchema>,
): Omit<CreateResourceParams, 'task_id' | 'step_id'> {
  return {
    type: data.type,
    data: data.type === 'url' ? data.dataUrl : data.data,
    title: data.title,
    description: data.description,
    keywords: data.keywords,
    file: data.file ?? undefined,
    file_name: data.fileName,
    s3_file: data.s3File ?? undefined,
  }
}

export function castZodFormToFAQsUpdate(data: z.infer<FAQFormSchema>): Omit<UpdateFAQsParams, 'task_id' | 'step_id'> {
  return {
    faqs: [
      {
        question: data.question,
        answer: data.answer,
        faq_id: data.faqId!,
      },
    ],
  }
}

export function castZodFormToFAQsCreate(data: z.infer<FAQFormSchema>): Omit<CreateFAQsParams, 'task_id' | 'step_id'> {
  return {
    faqs: [
      {
        question: data.question,
        answer: data.answer,
      },
    ],
  }
}

export function castStepToZodForm(data?: Partial<GetStepsResponse[0]>): z.infer<StepFormSchema> {
  return {
    stepId: data?.stepId ?? null,
    content: { text: '', file: null },
    name: data?.name ?? '',
    titleImage: data?.titleImage ?? '',
    introduction: data?.introduction ?? '',
    shortDescription: data?.shortDescription ?? '',
    title: data?.title ?? '',
    // deliveryMode: data?.deliveryMode ?? stepSchema.shape.deliveryMode.enum.Paraphrase,
    sectionObjectives: data?.sectionObjectives ?? '',
    // mock
    // access: 'open',
  }
}

export function castZodFormToStep(data: z.infer<StepFormSchema>): Omit<UpdateStepParams, 'step_id'> {
  return {
    name: data.name,
    title_image: data.titleImage ?? '',
    introduction: data.introduction ?? '',
    short_description: data.shortDescription ?? '',
    title: data.name ?? '',
    section_objectives: data.sectionObjectives,
    // delivery_mode: data.deliveryMode,
  }
}

export type ModuleFormSchema = typeof moduleSchema
export type FAQFormSchema = typeof faqSchema
export type ResourceFormSchema = typeof resourceSchema
export type ContentFormSchema = typeof contentSchema
export type StepFormSchema = typeof stepSchema
export type MetadataFormSchema = {
  module?: GenerateMetadataResponse['generated'][number]
  step?: GenerateMetadataResponse['generated'][number]
  resource?: GenerateMetadataResponse['generated'][number]
  demographic?: GenerateDemographicResponse['demographic']
  sectionObjectives?: GenerateObjectivesResponse['objectives']
}
