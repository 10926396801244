import type { ErrorObject } from 'serialize-error'

import type { StringWithAutocomplete } from '@/types/helpers'

/*
 * Enums
 */
export enum DeliveryMode {
  Quote = 0,
  Adaptation = 1,
  Paraphrase = 2,
}

export enum ContentModuleStatus {
  Published = 'published',
  Draft = 'draft',
  Removed = 'removed',
  PendingApproval = 'pending_approval',
  Testing = 'testing',
}

/*
 * Common Types
 */
export type SafeRequestResponse<ResponseT, ErrorDataT = { message?: string }> =
  | { ok: false; data: ErrorDataT; error: ErrorObject | undefined }
  | { ok: true; data: ResponseT }

export type ConfigResponse = ImportMeta['env']

type StepOrTaskIdParams<T extends string = 'task_id' | 'step_id'> = T extends 'task_id'
  ? { task_id: string; step_id?: never }
  : { step_id: string; task_id?: never }

type StepOrTaskOrResourceIdParams<T extends string = 'task_id' | 'step_id' | 'resource_id'> = T extends 'task_id'
  ? { task_id: string; step_id?: never; resource_id?: never }
  : T extends 'step_id'
    ? { step_id: string; task_id?: never; resource_id?: never }
    : { resource_id: string; task_id?: never; step_id?: never }

/*
 * Request Types
 */
export type LoginParams = { username: string; password: string }

export type ForgotParams = { username: string; old_password: string; new_password: string }

export type LogoutParams = { access_token: string }

export type RefreshTokenParams = { refresh_token: string }

export type CreateContentModuleParams =
  | { content: string | null }
  | { file: File }
  | { file_name: string; s3_file: string }

export type UploadParams = { file_path: string; file_content: File }

export type PrepareUploadParams = { fileType: string }

export type GetContentModuleParams = { task_id: string }

export type GetContentParams = StepOrTaskIdParams

export type GetResourcesParams = StepOrTaskIdParams

export type GetFAQsParams = StepOrTaskIdParams

export type UpdateContentModuleParams = {
  task_id: string
  priority?: number
  name?: string
  title?: string
  short_description?: string
  long_description?: string
  are_step_sequential?: boolean
  select_esk?: string
  categories?: string[]
  mappings?: string[]
  s3_file?: string | null // UUID
  file_name?: string | null
  demographic?: string | null
  visible_to_msk?: boolean
  allowed_for_all_sponsors?: boolean
  section_objectives?: string | null
  title_image?: string | null
  introduction?: string | null
}

export type CreateFAQsParams = {
  faqs: Array<{
    question: string
    answer: string
  }>
  task_id?: string
  step_id?: string
}

export type UpdateFAQsParams = {
  faqs: Array<{
    faq_id: string
    question: string
    answer: string
  }>
  task_id?: string
  step_id?: string
}

export type DeleteFAQsParams = {
  faq_ids: string
  task_id?: string
  step_id?: string
}

export type UpdateContentTextParams = StepOrTaskIdParams &
  ({ text: string } | { file: File } | { file_name: string; s3_file: string })

export type CreateResourceParams = {
  file_name?: string | null
  file?: File
  s3_file?: string | null // UUID
  type: 'url' | 'image' | 'video' | 'text'
  title: string
  description: string
  data?: string
  keywords: string[]
  task_id?: string
  step_id?: string
}

export type UpdateResourceParams = CreateResourceParams & { resource_id: string }

export type DeleteResourceParams = {
  resource_id: string
  task_id?: string
  step_id?: string
}

export type GetStepsParams = { task_id: string }

export type CreateStepParams = ({ content: string } | { file: File } | { file_name: string; s3_file: string }) & {
  task_id: string
  preceding_step_id?: string
}

export type DeleteStepParams = {
  step_id: string
  task_id: string
  showToast?: boolean
}

export type UpdateStepParams = {
  step_id: string
  name?: string
  title?: string
  title_image?: string
  short_description?: string
  introduction?: string
  delivery_mode?: DeliveryMode
  section_objectives?: string
}

export type ChangeContentModuleStatusParams = {
  task_id: string
  status: `${ContentModuleStatus}`
}

export type GenerateMetadataParams = StepOrTaskOrResourceIdParams & {
  number_of_options?: number
  field?:
    | 'name'
    | 'title'
    | 'short_description'
    | 'long_description'
    | 'introduction'
    | 'description'
    | 'section_objectives'
}

export type GenerateDemographicParams = {
  task_id: string
}

export type GenerateObjectivesParams = {
  task_id: string
}

export type GenerateFAQsParams = StepOrTaskIdParams

/*
 * Response Types
 */
export type LoginResponse = {
  status?: number
  message: string
  authenticationResult?: {
    accessToken: string
    expiresIn: number
    tokenType: string
    refreshToken: string
    idToken: string
  }
}

export type LoginResponseError = {
  message: string
  code: 'NOT_AUTHORIZED' | 'NEW_PASSWORD_REQUIRED'
}

export type ForgotResponse = {
  message: string
}

export type ForgotResponseError = {
  code?: 'OLD_PASSWORD_INCORRECT'
  message: string
}

export type PrepareUploadResponse = {
  filePath: string
  fileName: string
  s3File: string
}

export type CreateContentModuleResponse = {
  taskId: string
  inProcess: boolean
  generated?: {
    name: string
    title: string
    longDescription: string
    shortDescription: string
  }
}

export type UpdateContentModuleResponse = {}

export type UpdateContentTextResponse = {
  message: string
  inProcess: boolean
}

export type GetESKsResponse = Array<{
  eskId: string
  name: string
  type: unknown
  introduction: string
  systemPrompt: string
  allowedForSwitchCm: boolean
  ttsId: string
  topicId: string
  active: boolean
  createdAt: string
  additionalInformation: string
  avatarId: string
  category: string
  createdBy: string
  expertise: string
}>

export type GetContentModuleResponse = {
  approvable: boolean
  access: unknown // NOT IMPLEMENTED
  areStepSequential: boolean | null
  categories: string[] | null
  createdAt: string | null
  createdBy: string | null
  editable: boolean
  mappings: string[] | null
  name: string | null
  selectEsk: string | null
  sectionObjectives: string | null
  introduction: string | null
  shortDescription: string | null
  status: `${ContentModuleStatus}` | null
  taskId: string | null
  title: string | null
  titleImage: string | null
  inProcess: boolean | null
  faqs: GetFAQsResponse | null
  demographic: string | null
  priority: number | null
  visibleToMsk: boolean | null
  allowedForAllSponsors: boolean | null
}

export type GetContentModulesResponse = GetContentModuleResponse[]

export type GetContentResponse = {
  contentId: string
  stepId: string | null
  taskId: string | null
  inProcess: boolean
  content: string
}

export type GetFAQsResponse = Array<{
  faqId: string
  createdAt: string
  question: string
  answer: string
}>

export type GetResourcesResponse = Array<{
  resourceId: string
  type: 'url' | 'image' | 'video' | 'text'
  fileName: string | null
  data: string
  title: string
  description: string
  keywords: string[] | null
  s3File?: string
  inProcess?: boolean
}>

export type GetStepsResponse = Array<{
  stepId: string
  name: string | null
  title: string | null
  titleImage: string | null
  sectionObjectives: string | null
  shortDescription: string | null
  introduction: string | null
  createdAt: string
  deliveryMode: DeliveryMode | null
  faqs: GetFAQsResponse | null
}>

export type CreateStepResponse = {
  stepId: string
  inProcess: boolean
  generated?: {
    introduction: string
    name: string
    overview: string
    shortDescription: string
    title: string
  }
}

export type ChangeContentModuleStatusResponse =
  | {
      status: boolean
      message?: string
    }
  | {
      errors: Array<{
        error: string
        field: string
      }>
    }

export type GenerateMetadataResponse = {
  generated: [
    {
      name: string
      title: string
      description: string
      shortDescription: string
      longDescription: string
      introduction: string
    },
  ]
}

export type GenerateFAQsResponse = {
  questions: Array<{
    question: string
    answer: string
  }>
}

export type GenerateDemographicResponse = {
  demographic: string
}

export type GenerateObjectivesResponse = {
  objectives: string
}

export type CreateResourceResponse = {
  resourceId: string
  inProcess: boolean
}

export type UpdateResourceResponse = {
  message: string
  inProcess: boolean
}

export type CheckUserResponse = {
  access: {
    sme: boolean
    app: boolean
  }
  isSponsor: boolean
  isSponsorAdmin?: boolean
  isEditor: boolean
  sponsorId: string
  sponsorName: string
  userId: string

  // HACK: Below are additional fields that are the response of the API on stag and internal.
  message?: StringWithAutocomplete<'success'>
}

export type GetSponsorTopicsResponse = Array<{
  id: string
  key: string
  displayName: string
  subtopics: Array<{ id: string; key: string; displayName: string }>
  priority: number | null
}>

export type UpdateTopicPriorityParams = {
  topics: Array<{
    id: string
    displayName: string
    key: string
    priority: number
    subtopics: Array<{ id: string; displayName: string; key: string }>
  }>
}

/*
 * Analytics
 */

// Analytics Request Params
// ------------------------------------------------------------
export type DateRangeRequest = {
  /**
   * What date to filter the results from. Can either be a date 2021-01-01,
   * or a relative date, like -7d for last seven days, -1m for last month,
   * mStart for start of the month or yStart for the start of the year.
   *
   */
  date_from: string
  /**
   * What date to filter the results to. Can either be a date 2021-01-01,
   * or a relative date, like -7d for last seven days, -1m for last month,
   * mStart for start of the month or yStart for the start of the year.
   */
  date_to: string | null
  explicit_date: boolean
}

export type GetCmConversationsByDateParams = DateRangeRequest & {
  cm_id: string
}

export type GetTopicConversationsByDateParams = DateRangeRequest & {
  topic_id: string
}

export type GetActiveMembersByDateParams = DateRangeRequest

export type GetSmeLoginsByDateParams = DateRangeRequest

export type AllConversationsPerCmParams = DateRangeRequest

export type GetEditsPerCmParams = DateRangeRequest

export type GetCmEditsByDateParams = DateRangeRequest

// Analytics Response Types
// ------------------------------------------------------------
export type ActiveMembersByDateResponse = DateRange & {
  data: ActiveMembersByDateEntry[]
  stats: AnalyticsStats
}

export type SmeLoginsByDateResponse = DateRange & {
  data: SmeLoginsByDateEntry[]
  stats: AnalyticsStats
}

export type CmConversationByDateResponse = DateRange & {
  data: CmConversationByDateEntry[]
  stats: ConversationStats
}

export type TopicConversationByDateResponse = DateRange & {
  data: TopicConversationByDateEntry[]
  stats: ConversationStats
}

export type AllConversationsPerCmResponse = DateRange & {
  data: AllConversationsPerCmEntry[]
  stats: ConversationStats
}

export type EditsPerCmResponse = DateRange & {
  data: EditsPerCmEntry[]
  stats: AnalyticsStats
}

export type CmEditsByDateResponse = DateRange & {
  data: CmEditsByDateEntry[]
  stats: AnalyticsStats
}

// Analytics Response Entry Types
// ------------------------------------------------------------
/**
 * The start and end dates of the data returned from the analytics API.
 * These are the dates of the data returned, not necessarily the date range of the request.
 * Will be null if no data is returned. Dates are in the format YYYY-MM-DD.
 */
export type DateRange = {
  startDate: string | null
  endDate: string | null
}

export interface ContentModuleType {
  label: string
  task_id: string
}

export type BaseConversationEntry = {
  total: number // total number of conversations (unique + non-unique)
  unique: number // total number of unique user conversations
}

export type ActiveMembersByDateEntry = {
  date: string
  total: number
}

export type SmeLoginsByDateEntry = {
  date: string
  total: number
}

export type CmEditsByDateEntry = {
  date: string
  total: number
}

// Type for each entry in the histogram data
export type AllConversationsPerCmEntry = ContentModuleType &
  BaseConversationEntry & {
    total_messages: number // total number of messages in all conversations for the Content Module
  }

export type EditsPerCmEntry = ContentModuleType & {
  total: number
  unique: number
}

export type CmConversationByDateEntry = {
  date: string // YYYY-MM-DD
  total: number // total number of conversations on that date for the specific CM
  unique: number // total number of unique user conversations on that date for the specific CM
  total_messages: number // total number of messages in all conversations on that date for the specific CM
  all_conversations: number // total number of conversations on that date for all CM
}

export type TopicConversationByDateEntry = {
  date: string // YYYY-MM-DD
  total: number // total number of conversations on that date for the specific topic
  unique: number // total number of unique user conversations on that date for the specific topic
  total_messages: number // total number of messages in all conversations on that date for the specific topic
  all_conversations: number // total number of conversations on that date for all topics
}

// Analytics Stats Types
// ------------------------------------------------------------
export type AnalyticsStats = {
  average: number
  total: number
  max: number
  min: number
}

export type ConversationStats = {
  averageConversationLength: number
  maxConversationLength: number
  minConversationLength: number
  totalMessages: number
  totalConversations: number
  totalUniqueConversations: number
}
