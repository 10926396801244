import type { Instance, SnapshotOut } from 'mobx-state-tree'
import { types } from 'mobx-state-tree'

import { withRootStore } from '@/services/state/helpers/withRootStore'
import { withSetProp } from '@/services/state/helpers/withSetProp'
import { getObjectValues } from '@/utils/objects'

export enum ModalStatus {
  Open = 'open',
  Closed = 'closed',
}

export enum GlobalLoadingOverlayStatus {
  Fullscreen = 'fullscreen',
  Relative = 'relative',
  Closed = 'closed',
}

export const UIStoreModel = types
  .model('UIStore')
  .extend(withRootStore)
  .props({
    devModalStatus: types.optional(types.enumeration<ModalStatus>(getObjectValues(ModalStatus)), ModalStatus.Closed),
    searchModalStatus: types.optional(types.enumeration<ModalStatus>(getObjectValues(ModalStatus)), ModalStatus.Closed),
    globalLoadingOverlayStatus: types.optional(
      types.enumeration<`${GlobalLoadingOverlayStatus}`>(getObjectValues(GlobalLoadingOverlayStatus)),
      GlobalLoadingOverlayStatus.Closed,
    ),
  })
  .volatile<UIStoreVolatileProps>(() => ({
    routerLocationChangeFn: undefined,
    pendingStepDeletion: false,
    isNewContentModule: false,
  }))
  .extend(withSetProp)
  .actions(self => ({
    showLoadingOverlay(status: Exclude<`${GlobalLoadingOverlayStatus}`, 'closed'> = 'fullscreen') {
      self.globalLoadingOverlayStatus = status
    },
    hideLoadingOverlay() {
      self.globalLoadingOverlayStatus = GlobalLoadingOverlayStatus.Closed
    },
    setRouterLocationChangeFn(fn?: UIStoreVolatileProps['routerLocationChangeFn']) {
      self.routerLocationChangeFn = fn
    },
    setPendingStepDeletion(value: boolean) {
      self.pendingStepDeletion = value
    },
    reset() {
      self.devModalStatus = ModalStatus.Closed
      self.searchModalStatus = ModalStatus.Closed
      self.globalLoadingOverlayStatus = GlobalLoadingOverlayStatus.Closed
      self.pendingStepDeletion = false
      self.isNewContentModule = false
    },
    setIsNewContentModule(value: boolean) {
      self.isNewContentModule = value
    },
  }))

export interface UIStore extends Instance<typeof UIStoreModel> {}
export interface UIStoreSnapshot extends SnapshotOut<typeof UIStoreModel> {}

export interface UIStoreVolatileProps {
  routerLocationChangeFn?: (nextPath: string) => Promise<boolean | undefined> | (boolean | undefined)
  pendingStepDeletion?: boolean
  isNewContentModule?: boolean
}
