import { Group, Paper, Skeleton, Stack } from '@mantine/core'

export default function ChartSkeleton() {
  const tickTotal = 10
  return (
    <Paper radius="md" p="lg" shadow="sm" px="xl">
      <Stack gap="xs">
        <Skeleton height={16} width={150} radius="xl" />
        <Skeleton height={300} />
        <Group justify="space-between">
          {Array.from({ length: tickTotal }).map((_, index) => (
            <Skeleton height={8} width={25} radius="xl" key={index} />
          ))}
        </Group>
      </Stack>
    </Paper>
  )
}
