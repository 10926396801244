import { CompositeChart } from '@mantine/charts'
import type { ComboboxData } from '@mantine/core'
import { Group, Paper, Select, Stack, Tabs, Text, Title } from '@mantine/core'
import { IconEyeExclamation, IconMessage, IconPencil, IconRuler2 } from '@tabler/icons-react'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { ResponsiveContainer } from 'recharts'

import { StatInfoGraphic } from '@/components/StatInfoGraphic'
import { useGlobalState } from '@/hooks/useGlobalState'
import type { GetSponsorTopicsResponse } from '@/services/api/api.types'
import { selectStyles } from '@/styles/Chart.css'
import { conversationsSeries, formatChartDate, getRelativeDateRange } from '@/utils/analytics'

import { ConversationByDateTooltip } from './ConversationByDateTooltip'
import TabChartWrapper from './TabChartWrapper'

const TabKeys = {
  conversationLineChart: 'conversationLineChart',
  messageLineChart: 'messageLineChart',
} as const

export default function TopicInsights() {
  const { api } = useGlobalState()

  const topicsQuery = useQuery({
    queryKey: ['sponsor', 'topics'],
    queryFn: api.getSponsorTopics,
    initialData: [],
  })

  const [selectedTopic, setSelectedTopic] = useState<GetSponsorTopicsResponse[0] | null>(null)
  const [activeTab, setActiveTab] = useState<string | null>(TabKeys.conversationLineChart)

  const { data, isLoading, error } = useQuery({
    queryKey: ['analytics', 'topicConversationsByDate', selectedTopic?.id],
    queryFn: () =>
      api.getTopicConversationsByDate({
        topic_id: selectedTopic?.id ?? '',
        ...getRelativeDateRange(30),
      }),
    enabled: !!selectedTopic?.id, // Only run the query when we have a topic ID
  })

  const topicOptions: ComboboxData = topicsQuery.data.map(topic => ({
    value: topic.id,
    label: topic.displayName,
    disabled: !topic.id,
  }))

  useEffect(() => {
    if (topicsQuery.data?.length > 0 && !selectedTopic) {
      setSelectedTopic(topicsQuery.data[0])
    }
  }, [topicsQuery.data])

  return (
    <Stack>
      <Paper radius="md" p="lg" shadow="sm" px="xl">
        <Group justify="space-between" mb="md" align="top">
          <Select
            data={topicOptions}
            label="Topic"
            placeholder="Select a topic"
            value={selectedTopic?.id ?? null}
            onChange={value => {
              const topic = topicsQuery.data.find(topic => topic.id === value)
              setSelectedTopic(topic ?? null)
            }}
            miw={300}
            classNames={selectStyles}
          />

          <Stack align="flex-end" gap="md">
            <Group gap="xs">
              <Text size="sm" c="dimmed">
                {formatChartDate(data?.startDate ?? '', true)}
              </Text>
              <Text size="sm" c="dimmed">
                —
              </Text>
              <Text size="sm" c="dimmed">
                {formatChartDate(data?.endDate ?? '', true)}
              </Text>
            </Group>

            <Group gap="xl">
              <StatInfoGraphic
                icon={IconEyeExclamation}
                label="Unique Visitors"
                value={data?.stats.totalUniqueConversations ?? 0}
                tooltipText="Number of unique members who interacted with this topic"
              />

              <StatInfoGraphic
                icon={IconMessage}
                label="Conversations"
                value={data?.stats.totalConversations ?? 0}
                tooltipText="Total number of conversations for this topic"
              />

              <StatInfoGraphic
                icon={IconPencil}
                label="Member Messages"
                value={data?.stats.totalMessages ?? 0}
                tooltipText="Total number of messages sent by members using this topic"
              />

              <StatInfoGraphic
                icon={IconRuler2}
                label="Average Length"
                value={Math.round(data?.stats.averageConversationLength ?? 0)}
                tooltipText="Average length of a conversation using this topic"
              />

              <StatInfoGraphic
                icon={IconRuler2}
                label="Max Length"
                value={data?.stats.maxConversationLength ?? 0}
                tooltipText="Longest conversation using this topic"
              />
            </Group>
          </Stack>
        </Group>
      </Paper>

      <Paper radius="md" p="lg" shadow="sm" px="xl">
        <Tabs value={activeTab} onChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Tab value={TabKeys.conversationLineChart}>Conversations</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value={TabKeys.conversationLineChart}>
            <Title order={6} mb="md" pt="md">
              Number of Conversations over Time
            </Title>
            <TabChartWrapper isActive={activeTab === TabKeys.conversationLineChart} isLoading={isLoading} error={error}>
              <ResponsiveContainer width="100%" height={300}>
                <CompositeChart
                  data={data?.data ?? []}
                  dataKey="date"
                  curveType="linear"
                  series={conversationsSeries}
                  xAxisProps={{
                    tickFormatter: (date: string) => {
                      return formatChartDate(date)
                    },
                    angle: -45,
                    textAnchor: 'end',
                  }}
                  tooltipProps={{
                    content: ({ payload, label }) => {
                      if (!payload || payload.length === 0) return null

                      // Find the total value for this date point
                      const totalValue = payload.find(entry => entry.name === 'total')?.value || 0
                      const allConversationsValue = payload.find(entry => entry.name === 'allConversations')?.value || 1
                      const percentage = (totalValue / allConversationsValue) * 100

                      return <ConversationByDateTooltip label={label} percentage={percentage} payload={payload} />
                    },
                  }}
                />
              </ResponsiveContainer>
            </TabChartWrapper>
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </Stack>
  )
}
