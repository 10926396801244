import { Stack, Text, Tooltip } from '@mantine/core'
import type { Icon } from '@tabler/icons-react'

interface StatInfoGraphicProps {
  icon: Icon
  label: string
  value: number | string
  tooltipText: string
}

export function StatInfoGraphic({ icon: IconComponent, label, value, tooltipText }: StatInfoGraphicProps) {
  return (
    <Tooltip
      withArrow
      arrowSize={12}
      position="top"
      offset={8}
      color="cyan.8"
      closeDelay={50}
      label={tooltipText}
      multiline
      styles={{
        tooltip: {
          maxWidth: '250px',
        },
      }}>
      <Stack gap="xs">
        <IconComponent size={32} />
        <Text>{label}</Text>
        <Text fw="bold" size="lg">
          {value}
        </Text>
      </Stack>
    </Tooltip>
  )
}
